 <template>
  <div class="navbar flex-r">
  		    <div v-for="(item, index) in levelList" :key="item.path">
		        <span v-if="index == levelList.length - 1" class="itemfirstWord">{{ item.meta.title }}</span>
		    </div>
		    <div class="navbar-breadcrumb flex-1">
		      <el-breadcrumb separator="/">
		        <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
		          <span v-if="index == levelList.length - 1">{{ item.meta.title }}</span>
		          <a v-else @click.prevent="jumpPage(item)">{{ item.meta.title }}</a>
		        </el-breadcrumb-item>
		      </el-breadcrumb>
		    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: "Navbar",
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        levelList: null
      };
    },
    //计算属性
    computed: {
      ...mapGetters([
        'navbar'
      ])
    },
    watch: {
      $route() {
        this.getBreadcrumb();
      }
    },
    created() {
      this.getBreadcrumb();
    },
    mounted() {},
    methods: {
      logout() {
        this.$store.dispatch("LogOut").then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      },
      jumpPage(item) {
        this.$router.push(item.path);
      },
      getBreadcrumb() {
        let last = this.$route.matched.slice(-1)[0]
        setTimeout(() => {
          if (last.meta.selfbreadcrumb) {
            //this.levelList = this.navbarList
//          console.log(this.navbar)
            this.levelList = this.navbar
            // return
          } else {
            let matched = this.$route.matched.filter(item => item.name);
            this.levelList = matched.filter(
              item => item.meta && item.meta.title && item.meta.breadcrumb !== false
            );
          }
        }, 100)
      }
    }
  }
</script>
<style scoped lang="scss">
  .navbar {
    margin:20px;
    height: 60px;
    line-height: 60px;
    align-items: center;
    /*border-bottom: 1px solid #ddd;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    background: #fff;*/
    .itemfirstWord{
    	 font-weight: bold;
    	 font-size: 17px;
    }
  	.el-breadcrumb{
    	float: right;
        padding-right: 15px;
    }
    .el-breadcrumb__inner a{
      font-weight: 100;
    }

  }
  .avatar-wrapper {
    cursor: pointer;
    position: relative;
    line-height: initial;
    .user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
  }
</style>