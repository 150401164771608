<template>
  <div class="layout">
    <Header></Header>
    <div class="container">
      <Sidebar></Sidebar>
      <div class="main-container">
        <Navbar></Navbar>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { Header, Navbar, Sidebar } from "./components";
export default {
  name: "Layout",
  components: { Header, Sidebar, Navbar },
  mixins: [],
  computed: {},
  methods: {}
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  .container {
    flex: 1;
    display: flex;
    flex-direction: row;
    .main-container {
      flex: 1;
      background:#F5F5F5;
    }
  }
}
</style>
