<template>
  <div class="asidebar">
    <el-menu :default-active="activeMenu" :collapse="isCollapse" background-color="" text-color="#303133"
      active-text-color="#409EFF" :collapse-transition="false" router mode="vertical">
      <div v-for="route in routes" :key="route.path">
        <SidebarItem :item="route" :base-path="route.path" v-if="isBelongNavbar(route)"></SidebarItem>
      </div>
    </el-menu>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import SidebarItem from "./SidebarItem";
  export default {
    name: "Sidebar",
    components: { SidebarItem },
    mixins: [],
    props: {},
    data() {
      return {};
    },
    //计算属性
    computed: {
      ...mapGetters(["sidebar", "navbarActiveName"]),
      routes() {
        return this.$router.options.routes;
      },
      isCollapse() {
        return !this.sidebar.opened;
      },
      //激活
      activeMenu() {
        const route = this.$route;
        const { meta, path } = route;
        // if set path, the sidebar will highlight the path you set
        if (meta.activeMenu) {
          return meta.activeMenu;
        }
        return path;
      }
    },
    watch: {},
    beforeCreate() { },
    created() { },
    beforeMount() { },
    mounted() { },
    beforeUpdate() { },
    updated() { },
    //keep-alive 组件激活时调用
    activated() { },
    //keep-alive 组件停用时调用。
    deactivated() { },
    //销毁之前
    beforeDestroy() { },
    //销毁后
    destroyed() { },
    methods: {
      isBelongNavbar(itemRoute) {
        if (itemRoute.meta && itemRoute.meta.belongNavbar) {
          if (itemRoute.meta.belongNavbar === this.navbarActiveName) {
            return true;
          } else return false;
          
        } else return false;
      }
    }
  };
</script>
<style scoped lang="scss">
  .asidebar {
    width: 200px;
    height: 100%;
  }

  .el-menu {
    height: 100%;
  }
</style>